<template>
  <div class="content">
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(rgba(24,62,49,0.2),black)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >
      <section class="col-lg-8 col-sm-12" >
        <HeadImage :clamp="true" :image="djImg" title="DJ Data Base" :color="primaryColor" caption="Reference Book"></HeadImage>
        <br>
        <div id="artists" class="row">
          <div v-for="(artist, idx) in artists"
               :key="idx" class="col-sm-12 " >
            <div v-if="artist.metadata" class="text-left card card-animation-on-hover-no-bg">
              <router-link :to="{name: 'Artist', params: {slug: artist.slug} }" style="font-size: 1rem;margin: 0.2rem"
              >

                <img
                  v-if="artist.metadata.main_image.imgix_url"
                  v-lazy=" artist.metadata.main_image.imgix_url + '?q=&w=50&h=50&fit=clamp' "
                  style="float:left;margin-right: 10px;z-index: 12; box-shadow: 0px 10px 15px 0px rgb(0 0 0.5);border-radius: 40px;border: 1px solid #b69603"
                  align="left"
                  v-bind:alt="artist.title"
                  height="50px"
                  width="50px"
                />
                <p style="margin-top:10px;font-weight: bold;font-size: 1rem">{{artist.title}}</p>
              </router-link>
            </div>
          </div>
        </div>

        <hr>
        <p>Showing {{ (val > 1 ? pageSize + 1 : 1) }} - {{ artists ? artists.length + (val > 1 ? pageSize : 0) : 0 }} of
          {{ total }} DJ's | <a style="display: inline-block" class="btn btn-link" @click="showAll" v-if="!showingAll">Show
            all ({{ total }})</a></p>

        <BasePagination type="primary" :per-page="pageSize" :total="total" :value="val" v-if="!showingAll"
                        :nextPage="nextPage" :prevPage="prevPage" :changePage="changePage"></BasePagination>


      </section>
      <aside class=" col-4 col-xl-4 d-none d-xl-inline">
        <SmartMenu :links="links" :logo="this.djImg" top-section-name="TALK TO US" cta-text="CONTACT US"
                   :primary-color="secondaryColor">

          <div class="card">
            <p>Want to talk with some one? <br> Drop us a line. We are here to assist.</p>
          </div>


        </SmartMenu>
      </aside>

    </article>
  </div>

</template>

<script>
import BasePagination from '../../components/BasePagination.vue';
import ImgCard from "@/components/Cards/ImgCard";
import MiniArtistCard from "@/components/Cards/MiniArtistCard";
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import HeadImage from "@/components/HeadImage/HeadImage";

export default {
  name: 'Artists',
  metaInfo() {
    return {
      title: `Tulum - DJ Lineup`,
      meta: [
        {
          name: 'description',
          content: `DJ's who will be playing or played in Tulum`,
        },
        {
          name: 'keywords',
          content: `Tulum DJs Lineup Artists Electronic Music`,
        },
      ],
    };
  },
  props: ['slug'],
  data: () => {
    return {
      table: [],
      color: 'blue',
      djImg: "https://imgix.cosmicjs.com/f511ab10-7121-11ef-9f27-0d70a946df1f-dj-schedule.jpg",
      showingAll: false,
      showPalms: false,
      pageSize: 50,
      val: undefined,
      page: 0,
      primaryColor: '#ba5d38',
      secondaryColor: '#64665a',
      showModal: false,
    };
  },
  components: {
    HeadImage,
    MiniArtistCard,
    ImgCard,
    SmartMenu,
    BasePagination
  },
  created: function () {
    // this.$store.dispatch('getArtists');
    this.$store.dispatch('getArtists', {start: 0, limit: this.pageSize});
    this.$store.dispatch('getLinks', {slug: 'djs-'});

  },
  methods: {
    artistImg: function (artist) {
      return artist.metadata?.main_image?.imgix_url ||  'https://imgix.cosmicjs.com/a47ded40-484a-11ee-89ab-17371fc03105-image.png';
    },
    changePage: function (page) {
      this.page = page;
      this.val = this.page;
      this.$store.dispatch('getArtists', {start: (this.page * 10), limit: this.pageSize});

    },
    nextPage: function () {
      this.page++;
      this.val = this.page;
      this.$store.dispatch('getArtists', {start: (this.page * 10), limit: this.pageSize});
    },
    showAll: function () {
      this.$store.dispatch('getArtists', {start: 0, limit: this.total});
      this.showingAll = true;

    },
    prevPage: function () {
      this.page--;
      this.val = this.page;

      this.$store.dispatch('getArtists', {start: (this.page * 10), limit: 20});
    }
  },
  computed: {
    total: {
      get() {
        return this.$store.state.total;
      },
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    artists: {
      get() {
        return this.$store.state.artists;
      },
    },
  },
};
</script>

